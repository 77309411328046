import "core-js/stable";
import "regenerator-runtime/runtime";
import "./app.scss";
import Nav from "./js/Nav";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import Menu from "./js/Menu";
import Popup from "./js/Popup";
import Headroom from "headroom.js";
import Slider from "./js/Slider";

gsap.registerPlugin(ScrollTrigger);

function action(attr, func) {
  if (attr.indexOf(".") !== -1 || attr.indexOf("#") !== -1) {
    document.querySelectorAll(attr).forEach((el) => func(el));
  } else {
    document
      .querySelectorAll(`[data-action="${attr}"]`)
      .forEach((el) => func(el));
  }
}

function runActions() {
  action("Slider", Slider);
  Popup(document.querySelectorAll(".popup-btn"));
  Menu(document.querySelectorAll(".mob-nav-btn"));
  Nav(document.querySelectorAll(".nav-btn"));

  const headroomElement = document.querySelector("#main-header");
  const headroom = new Headroom(headroomElement, {
    offset: 50,
  });
  headroom.init();

  window.addEventListener("beforeunload", function () {
    window.scrollTo(0, 0);
  });

  // const dropdown = document.querySelector(".dropdown");
  // const toggleButton = document.getElementById("toggleDropdown");
  // dropdown.addEventListener("click", function (event) {
  //   event.stopPropagation();
  //   dropdown.classList.toggle("active");
  //   toggleButton.textContent = dropdown.classList.contains("active")
  //     ? "Close"
  //     : "Menu";
  // });

  const initialScale = 1;
  const endScale = 1.5;
  const scaleDivs = document.querySelectorAll(".scale-div");
  scaleDivs.forEach((scaleDiv) => {
    gsap.set(scaleDiv, {
      scale: initialScale,
    });
    gsap.to(scaleDiv, {
      scale: endScale,
      opacity: 0,
      scrollTrigger: {
        trigger: scaleDiv,
        start: "top 20%",
        end: "bottom 30%",
        scrub: true,
      },
    });
  });

  const popupImages = document.querySelectorAll(".popup-img");
  popupImages.forEach((popupImg, index) => {
    gsap.fromTo(
      popupImg,
      { opacity: 0 },
      {
        opacity: 1,
        scrollTrigger: {
          trigger: popupImg,
          start: "top 70%",
          end: "top 35%",
          scrub: true,
          toggleActions: "play none none reverse",
        },
      }
    );
  });

  var headingElement = document.getElementById("glitchHeading");
  var headingText = headingElement.textContent.trim();
  var characters = headingText.split("");
  var newHTML = characters
    .map(function (character) {
      if (character === " ") {
        return character;
      } else {
        return '<span class="glitch-letter">' + character + "</span>";
      }
    })
    .join("");

  headingElement.innerHTML = newHTML;

  var glitchElements = document.querySelectorAll("#glitchWords");
  glitchElements.forEach(function (wordsElement) {
    var wordsText = wordsElement.textContent.trim();
    var words = wordsText.split("");
    var newHTML = words
      .map(function (word) {
        if (word === " ") {
          return word;
        } else {
          return "<span class='fade-in-words'>" + word + "</span>";
        }
      })
      .join("");
    wordsElement.innerHTML = newHTML;
  });

  const containers = document.querySelectorAll(".fade-in-words");
  containers.forEach((container) => {
    const words = container.querySelectorAll("span");
    words.forEach((word, index) => {
      gsap.fromTo(
        word,
        {
          opacity: 0,
          y: 20,
        },
        {
          opacity: 1,
          y: 0,
          scrollTrigger: {
            trigger: word,
            start: "top 70%",
            end: "top 40%",
            scrub: 1,
          },
        }
      );
    });
  });

  const initialScale2 = 1;
  const endScale2 = 1.2;
  const scaleDivs2 = document.querySelectorAll(".scale-div-2");
  scaleDivs2.forEach((scaleDiv2) => {
    gsap.set(scaleDiv2, {
      scale: initialScale2,
    });
    gsap.to(scaleDiv2, {
      scale: endScale2,
      scrollTrigger: {
        trigger: scaleDiv2,
        start: "top 70%",
        end: "bottom 10%",
        scrub: true,
      },
    });
  });

  // Add this JavaScript to disable scrolling for the first 7 seconds
  document.addEventListener("DOMContentLoaded", function () {
    document.body.classList.add("disable-scroll");
    setTimeout(function () {
      document.body.classList.remove("disable-scroll");
    }, 7000); // 7000 milliseconds (7 seconds)
  });

  const container = document.getElementById("distortion-video-container");
  setTimeout(function () {
    container.style.opacity = "0";
    setTimeout(function () {
      container.classList.add("hidden");
    }, 2000);
  }, 7000);

  const glitchcontainer = document.getElementById("glitch-video");
  setTimeout(function () {
    glitchcontainer.style.opacity = "0";
    setTimeout(function () {
      glitchcontainer.classList.add("hidden");
    }, 2000);
  }, 6200);

  const section = document.querySelector(".scale-div");
  gsap.to(section, {
    opacity: 1,
    duration: 1,
    scrollTrigger: {
      trigger: section,
      start: "top 80%",
      end: "bottom bottom",
      scrub: true,
    },
  });
}

runActions();

// Only used in dev
if (module.hot) {
  module.hot.accept();
}
