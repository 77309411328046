export default function Nav(els) {
  const nav = document.querySelector(".nav-popup");
  const navToggle = document.querySelector(".nav-toggle");
  els.forEach((el) =>
    el.addEventListener("click", () => {
      document.body.classList.toggle("overflow-hidden");
      nav.classList.toggle("hidden");
      nav.classList.toggle("opacity-0");
      navToggle.classList.toggle("rotate-[90deg]");
    })
  );
}
